import React, { FC } from 'react';
import './Field.css';

type MyProps = {
  children: any;
};
const Field: FC<MyProps> = ({ children }) => {
  return <div className='fieldWrapper'>{children}</div>;
};

export default Field;
