import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import InputMask from 'react-input-mask';
//import { get } from 'react-lodash';
import 'react-datepicker/dist/react-datepicker.css';
import Address from '../common/Address';
import {
  AddressWrapper,
  FieldLeft,
  Layout,
  RequiredField,
  SectionContainer,
} from '../common/common.style';
import { AppProps } from '../../types';
import { onDateChanged } from '../../utils/utils';
import SelectedInput from '../common/SelectedInput';
import Field from '../common/Field/Field';

const RentalData: FC<AppProps> = ({
  data,
  setData,
  onChange,
  namespace = '',
}) => {
  const LeaseStartDate = _.get(
    data,
    `${namespace}Landlord.LeaseStartDate`,
    new Date().toDateString(),
  );

  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{namespace} Rental Data</div>
      <Layout>
        <AddressWrapper>
          <Address
            onChange={onChange}
            namespace={`${namespace}Landlord`}
            data={data}
          />
        </AddressWrapper>

        <Field>
          <div>{namespace} Rent:</div>
          <RequiredField required={data.PhoneNumber === ''}>
            <SelectedInput //data.CurrentLandlord.Rent
              className='fieldInput'
              name={`${namespace}Landlord.Rent`}
              value={_.get(data, `${namespace}Landlord.Rent`, 0)}
              data-numeric={true}
              onChange={onChange}
            />
          </RequiredField>
        </Field>

        <Field>
          <div>{namespace} Landlord Name:</div>
          <input
            className='fieldInput'
            name={`${namespace}Landlord.Name`}
            value={_.get(data, `${namespace}Landlord.Name`, '')}
            onChange={onChange}
          />
        </Field>

        <Field>
          <div>Lease Start Date:</div>
          <DatePicker
            className='fieldInput'
            selected={new Date(LeaseStartDate)}
            name={`${namespace}Landlord.LeaseStartDate`}
            id={`${namespace}Landlord.LeaseStartDate`}
            onChange={(e) => {
              onDateChanged(e, `${namespace}Landlord.LeaseStartDate`, onChange);
            }}
          />
        </Field>

        <Field>
          <div>{namespace} Landlord Phone:</div>
          <InputMask
            className='fieldInput'
            mask='(999) 999 9999'
            name={`${namespace}Landlord.Phone`}
            value={_.get(data, `${namespace}Landlord.Phone`, '')}
            onChange={onChange}
          />
        </Field>
        <Field>
          <div>Lease Term (years):</div>
          <SelectedInput
            className='fieldInput'
            name={`${namespace}Landlord.LeaseLong`}
            data-numeric={true}
            value={_.get(data, `${namespace}Landlord.LeaseLong`, 0)}
            onChange={onChange}
          />
        </Field>
      </Layout>
    </SectionContainer>
  );
};

export default RentalData;
