import React from 'react';
import '../App/App.css';
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

const Success = props => {
     const params = useLocation().search;
   if(params.indexOf('success') === -1){
      return <></>;
   }
    return (
        <div className="ReturnMessage">
            <div className='greenField'>
            <h2>Your payment was accepted</h2>
            <h4>Our agents will contact you soon!</h4>
            <div className='greenField'></div>
            </div>
            <Link to={'/?x=1'}>
                <div className="link">Go back to Home page</div>
            </Link>
        </div>
    );
};



export default Success;