import React, { useState } from 'react';
import BusyButton from '../common/BusyButton';
import { useLocation } from 'react-router-dom';
import { applicationFee } from '../../constants';

const Payment = () => {
  const [subject, setSubject] = useState('');
  const [amount, setAmount] = useState('');
  const [showError, setShowError] = useState(false);
  const [showError2, setShowError2] = useState(false);

  const params = useLocation().search;
  if (params.indexOf('success') > -1) {
    return <></>;
  }

  const flatTheAmount = (amt) => {
    let amount = amt.replace(/\$/, '');

    if (amt.indexOf('.') > -1) {
      const arr = amount.split('.');
      if (arr[0].length === 1 && arr[0] === '0') {
        //number that starts with "0."
        if (arr[1].length === 1) {
          amount = `${arr[1]}0`;
        }
        if (arr[1].length === 2) {
          amount = arr[1];
        }
      } else {
        //we have dollar number and the cents is a single digit
        if (arr[1].length === 1) {
          amount = `${arr[0]}${arr[1]}0`;
        }
      }
    } else {
      //in case we dont have any decimal, add .00
      amount = `${amt}.00`;
    }

    return amount.replace(/\./, '');
  };

  const pay = async () => {
    const reg = /(?=.*\d)^\$?(([1-9]\d{0,9}(,\d{3})*)|0)?(\.\d{1,2})?$/;
    let willReturn = false;
    if (!amount.match(reg)) {
      setShowError(true);
      willReturn = true;
    }

    if (!subject) {
      setShowError2(true);
      willReturn = true;
    }

    if (willReturn) {
      return;
    }
    const flatAmount = flatTheAmount(amount);
    console.log(flatAmount);
    let clearText = subject.replace(/>/g, '');
    clearText = clearText.replace(/</g, '');
    const response = await fetch(
      `https://dkassociates.us/stripe.php?productName=${clearText}&amount=${flatAmount}&time=${new Date().getTime()}`,
    );
    const sessionId = await response.json();
    const stripe = window.Stripe('pk_live_Shp5iQek2LxICuD4TTL0cDVr00go7uOGk1');
    //const stripe = window.Stripe('pk_test_8ADgd3NmpbMRBGbm8qgDyy1V000EICYUWj');
    stripe.redirectToCheckout({
      sessionId: sessionId.sessionId,
    });
  };

  return (
    <div className='main-form'>
      <div className='field'>
        <div className='greenField'>
          Please type payment subject like "Application Fee" or "Security
          Deposit" etc.
        
        <input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          onKeyDown={() => setShowError2(false)}
        />
        
        <div className='numeric-alert'>
          {showError2 && <div>Please add subject</div>}
        </div>
        </div>
      </div>

      <div className='field'>
        <div className='greenField'>
        <div>Please type any Amount</div>
      <input
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        onKeyDown={() => setShowError(false)}
        placeholder={`Application fee is ${applicationFee}`}
      />
      <div className='numeric-alert'>
        {showError && <div>Please type monetary value</div>}
      </div>
      </div>
      </div>
      <div className='field'>
        <div className='payment-button-margin'>
          <BusyButton Caption='Proceed to the payments service.' OnClick={pay} />   
        </div>
             
      </div>
    </div>
  );
};

export default Payment;
