import React, { FC, useEffect, useState } from 'react';
import { appData, AppProps, IOccupant } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {
  CloseIcon,
  StandardButton,
  OccupantRecord,
  SectionContainer,
} from '../common/common.style';
import Field from '../common/Field/Field';

const AddMoreIncomes: FC<AppProps> = ({ data, onChange, setData }) => {
  useEffect(() => {
    if (!data.MoreIncomes) {
      const newData: appData = { ...data, MoreIncomes: [] };
      setData(newData);
    }
  }, []);
  const addIncomeSource = () => {
    const newData = { ...data };
    if (!newData.MoreIncomes) {
      newData.MoreIncomes = [];
    }
    newData.MoreIncomes.push({});
    setData(newData);
  };
  const removeRecord = () => {
    const newData = { ...data };
    newData.MoreIncomes!.pop();
    setData(newData);
  };
  // useEffect(() => {
  //     if (data.occupantNumber !== occupantNumber) {
  //         const event = {
  //             //newData[e.target.name] = e.target.value;
  //             target: {
  //                 name: 'occupantNumber',
  //                 value: occupantNumber,
  //                 dataset: {}
  //             }
  //         }
  //         onChange(event)
  //     }

  // }, [occupantNumber, onChange, data.occupantNumber])
  // const arr = []
  // for (let i = 0; i < data.occupantNumber; i++) {
  //     arr.push(i);
  // }
  //https://github.com/FortAwesome/Font-Awesome/tree/master/js-packages/%40fortawesome/free-regular-svg-icons
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Add Additional Incomes</div>
      <StandardButton onClick={addIncomeSource}>
        {' '}
        click to add Additional Source{' '}
      </StandardButton>
      <div className='fieldsLayout'>
        {(data.MoreIncomes || []).map((source: IOccupant, index: number) => (
          <OccupantRecord>
            <CloseIcon onClick={() => removeRecord()}>
              <FontAwesomeIcon icon={faWindowClose} />
            </CloseIcon>
            <Field>
              <div>Source Name:</div>
              <input
                className='fieldInput'
                name={`Arr-MoreIncomes-SourceName-${index}`}
                value={
                  data.MoreIncomes ? data.MoreIncomes[index].SourceName : ''
                }
                onChange={onChange}
              />
            </Field>
            <Field>
              <div>Monthly Amount:</div>
              <div>
                <input
                  className='fieldInput'
                  name={`Arr-MoreIncomes-Amount-${index}`}
                  value={data.MoreIncomes ? data.MoreIncomes[index].Amount : ''}
                  onChange={onChange}
                />
              </div>
            </Field>
            <Field>
              <div>Comments:</div>
              <div>
                <input
                  className='fieldInput'
                  name={`Arr-MoreIncomes-Comments-${index}`}
                  value={
                    data.MoreIncomes ? data.MoreIncomes[index].Comments : ''
                  }
                  onChange={onChange}
                />
              </div>
            </Field>
          </OccupantRecord>
        ))}
      </div>
    </SectionContainer>
  );
};

export default AddMoreIncomes;
