import React, { FC } from 'react';
import { useEffect } from 'react';
import { AppProps } from '../../types';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import Address from '../common/Address';
import './Occupation.css';
import {
  AddressWrapper,
  Layout,
  SectionContainer,
  SectionHeader,
} from '../common/common.style';
import SelectedInput from '../common/SelectedInput';
import { LengthField } from './Occupation.style';
import Field from '../common/Field/Field';

const Occupation: FC<AppProps> = ({ data, onChange, namespace }) => {
  useEffect(() => {
    //Saving previous page Rental Data
  }, []);
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{`${namespace} Employer`}</div>
      <AddressWrapper>
        <div className='fieldsLayout'>
          <Field>
            <div>Occupation:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.Occupation`}
              value={_.get(data, `${namespace}Job.Occupation`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Employer:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.EmployerName`}
              value={_.get(data, `${namespace}Job.EmployerName`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Self Employed DBA:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.Dba`}
              value={_.get(data, `${namespace}Job.Dba`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Business Phone:</div>
            <InputMask
              className='fieldInput'
              mask='(999) 999 9999'
              name={`${namespace}Job.BusinessPhone`}
              value={_.get(data, `${namespace}Job.BusinessPhone`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Type of business:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.TypeOfBusiness`}
              value={_.get(data, `${namespace}Job.TypeOfBusiness`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Position Held:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.PositionHeld`}
              value={_.get(data, `${namespace}Job.PositionHeld`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>Name and title of supervisor:</div>
            <input
              className='fieldInput'
              name={`${namespace}Job.Supervisor`}
              value={_.get(data, `${namespace}Job.Supervisor`, '')}
              onChange={onChange}
            />
          </Field>
          <Field>
            <div>How Long:</div>
            <div className='yearsMonths'>
              <div>
                years:
                <LengthField>
                  <SelectedInput
                    className='fieldInput'
                    name={`${namespace}Job.HowLongYears`}
                    value={_.get(data, `${namespace}Job.HowLongYears`, 0)}
                    onChange={onChange}
                  />
                </LengthField>
              </div>
              <div>
                months:
                <LengthField>
                  <SelectedInput
                    className='fieldInput'
                    name={`${namespace}Job.HowLongMonths`}
                    value={_.get(data, `${namespace}Job.HowLongMonths`, 0)}
                    onChange={onChange}
                  />
                </LengthField>
              </div>
            </div>
          </Field>
          <Field>
            <div>Monthly Gross Income:</div>
            <SelectedInput
              className='fieldInput'
              name={`${namespace}Job.MonthlyGrossIncome`}
              value={_.get(data, `${namespace}Job.MonthlyGrossIncome`, 0)}
              onChange={onChange}
            />
          </Field>
        </div>
      </AddressWrapper>
      <Address onChange={onChange} namespace={`${namespace}Job`} data={data} />
    </SectionContainer>
  );
};

export default Occupation;
