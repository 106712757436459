import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DATA_KEY, MIN_AGE } from '../../constants';
import { appData } from '../../types';
import { calcAgeYears, daysDiff } from '../../utils/utils';
import MissingLicDialog from '../DrivingLic/MissingLicDialog';
import BusyButton from './BusyButton';
import { StandardButton, ButtonsWrapper, ButtonBox } from './common.style';
import MissingLandlordDialog from './MissingLandlordDialog';

const Buttons: FC<any> = ({
  data,
  setData,
  setCurrentPage,
  currentPage,
  routes,
}) => {
  let history = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showMissingLic, setShowMissingLic] = useState<boolean>(false);
  useEffect(() => {
    if (history) {
      history.replace(routes[currentPage]);
    }
  }, [currentPage, history, routes]);
  const validateRentalData = (): boolean => {
    const currentLandlordFound: boolean =
      _.get(data, 'CurrentLandlord.Name', '') !== '';
    const currentPhoneFound: boolean =
      _.get(data, 'CurrentLandlord.Phone', '') !== '';
    const prevLandlordFound: boolean =
      _.get(data, 'PreviousLandlord.Name', '') !== '';
    const prevPhoneFound: boolean =
      _.get(data, 'PreviousLandlord.Phone', '') !== '';
    return (
      currentLandlordFound &&
      currentPhoneFound &&
      prevLandlordFound &&
      prevPhoneFound
    );
  };
  const validatePersonalData = (): boolean => {
    const foundName: boolean = data.FullName !== '';
    const foundPhone: boolean = data.PhoneNumber !== '';
    const oldEnough: boolean = calcAgeYears(data.DateOfBirth) > MIN_AGE; //daysDiff(new Date('2000-02-02'));
    const foundSSN: boolean = data.SSN !== '';
    const foundLicense: boolean = data.DrivingLicenceNumber !== '';
    const foundLicenseState: boolean = data.LicenseState !== '';
    const foundEmail: boolean = data.Email !== '';
    const foundApartment: boolean = data.ApartmentAddress !== '';
    if (
      foundName &&
      foundPhone &&
      oldEnough &&
      foundLicense &&
      foundEmail &&
      foundSSN &&
      foundLicenseState &&
      foundApartment
    ) {
      return true;
    }
    return false;
  };
  const performNext = () => {
    let valid = false;
    switch (currentPage) {
      case 1:
        valid = validatePersonalData();
        break;
      case 2:
        valid = validateRentalData();
        if (!valid) {
          setOpenDialog(true);
          return;
        }
        break;
      case 5:
        valid = data.LicFront && data.LicBack;
        if (!valid) {
          setShowMissingLic(true);
          return;
        }
        break;
      default:
        valid = true;
    }
    if (valid) {
      const nextPageIndex: number = currentPage + 1;
      setCurrentPage(nextPageIndex);
    }

    localStorage.setItem(DATA_KEY, JSON.stringify(data));
  };

  const continueFlow = () => {
    setOpenDialog(false);
    localStorage.setItem(DATA_KEY, JSON.stringify(data));
    const nextPageIndex: number = currentPage + 1;
    setCurrentPage(nextPageIndex);
  };
  return (
    <>
      <MissingLandlordDialog
        open={openDialog}
        handleClose={(o: boolean) => {
          setOpenDialog(o);
        }}
        continueFlow={continueFlow}
      />

      <MissingLicDialog open={showMissingLic} setOpen={setShowMissingLic} />
      <ButtonsWrapper>
        <ButtonBox>
          {currentPage > 1 && (
            <StandardButton onClick={() => setCurrentPage(currentPage - 1)}>
              Prev
            </StandardButton>
          )}
        </ButtonBox>

        <ButtonBox>
          {currentPage < routes.length - 1 && (
            <BusyButton Caption='Next' OnClick={performNext} />
            // <StandardButton onClick={() => performNext()}>Next</StandardButton>
          )}
        </ButtonBox>
      </ButtonsWrapper>
    </>
  );
};

export default Buttons;
