import { format } from 'date-fns';
import { ROOT_URL } from '../constants';
export const getApiUrl = (api: string, queryString?: string) => {
  if (!queryString) {
    queryString = '';
  } else {
    queryString = '&' + queryString;
  }
  return `${ROOT_URL}${api}.php?cb=${new Date().getTime()}${queryString}`;
};

export const NumericFilter = (input: string): string => {
  const strArr = input.split('');
  let foundDot = false;
  let foundDash = false;
  return strArr.reduce((str, ch) => {
    if (ch === '.' && !foundDot) {
      str = str + '.';
      foundDot = true;
      return str;
    }

    if (!foundDash && ch === '-' && input.indexOf('-') === 0) {
      foundDash = true;
      str = str + '-';
      return str;
    }
    if (/[0-9]+/.test(ch)) {
      str = str + ch;
      return str;
    }
    return str;
  }, '');
};

//today = new Date();
//today.format('dd-m-yy');
export const formatDate = (date: Date, forceExtraDay: boolean = false) => {
  let current_datetime = new Date(date);
  let year = current_datetime.getFullYear();
  let month = current_datetime.getMonth() + 1;

  let sMonth: string = month.toString();
  if (sMonth.length === 1) {
    sMonth = `0${sMonth}`;
  }
  let day: number = current_datetime.getDate(); //.toString();
  let sDay = day.toString();
  if (sDay.length === 1) {
    sDay = `0${day}`;
  }
  return `${year}-${sMonth}-${sDay}`;
};

export const calcAgeYears = (DOB: string) => {
  var millisecondsBetweenDOBAnd1970 = Date.parse(DOB);
  var millisecondsBetweenNowAnd1970 = Date.now();
  var ageInMilliseconds =
    millisecondsBetweenNowAnd1970 - millisecondsBetweenDOBAnd1970;
  //--We will leverage Date.parse and now method to calculate age in milliseconds refer here https://www.w3schools.com/jsref/jsref_parse.asp

  var milliseconds = ageInMilliseconds;
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  var day = hour * 24;
  //var month = day * 30;
  /*using 30 as base as months can have 28, 29, 30 or 31 days depending a month in a year it itself is a different piece of comuptation*/
  var year = day * 365;

  //let the age conversion begin
  var years = Math.round(milliseconds / year);
  // var months = years * 12;
  // var days = years * 365;
  // var hours = Math.round(milliseconds / hour);
  // var seconds = Math.round(milliseconds / second);

  return years;
};
export const daysDiff = (dt2: any) => {
  const dt1 = new Date();
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24),
  );
};

export const onDateChanged = (date: any, name: string, onChange: any) => {
  //newData[e.target.name] = e.target.value;
  // const value = `${date.getYear() + 1900}-${
  //   date.getMonth() + 1
  // }-${date.getDate()}`;
  const _date = new Date(date);
  date.setDate(_date.getDate() + 1);
  const value = format(_date, 'MM/dd/yyyy');
  const e = {
    target: {
      name,
      value,
      dataset: {},
    },
  };
  onChange(e);
};

export const validateEmail = (email: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const getDate = (date: string) => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

export const assertToday = (date: string) => {
  if (!date) {
    return false;
  }
  const inputDate = new Date(date);
  const today = new Date();
  const sameYear = today.getFullYear() === inputDate.getFullYear();
  const sameMonth = today.getMonth() === inputDate.getMonth();
  const sameDay = today.getDay() === inputDate.getDay();

  return sameDay || sameMonth || sameYear;
};
