import { NumericFilter } from '../utils/utils';
import _ from 'lodash';

export const useForm = (data: any, setData: (input: any) => void) => {
  const onChange: any = (e: any) => {
    let newData = {
      ...data,
    };

    if (e.target.type === 'checkbox') {
      _.set(newData, e.target.name, !e.target.checked);
    } else if (e.target.dataset.numeric) {
      if (e.target.value === '') {
        _.set(newData, e.target.name, 0);
      } else {
        _.set(newData, e.target.name, NumericFilter(e.target.value));
      }
    } else if (e.target.name.indexOf('Arr-') === 0) {
      //Handling Array   //Arr-Occupants-Age-${index}
      const arrHeaders: string[] = e.target.name.split('-');
      const arrName = arrHeaders[1];
      const propName = arrHeaders[2];
      const index = parseInt(arrHeaders[3]);
      const newArr = [...newData[arrName]];
      newArr[index][propName] = e.target.value;
      newData[arrName] = newArr;
    } else {
      _.set(newData, e.target.name, e.target.value); // newData[e.target.name] = e.target.value;
    }
    setData(newData);
  };
  return { onChange };
};
