import React, { FC } from 'react';
import { ImgWrapper, LoaderImage, StandardButton } from './common.style';
import loader from '../../images/loading.gif';

type myProps = {
  Caption: string;
  OnClick: () => void;
  ShowLoader?: boolean;
  UseLoader?: boolean;
};
const BusyButton: FC<myProps> = ({
  Caption,
  OnClick,
  ShowLoader,
  UseLoader = false,
}) => {
  return (
    <StandardButton onClick={() => OnClick()}>
      {Caption}
      {UseLoader && (
        <ImgWrapper>
          {ShowLoader && <LoaderImage src={loader} alt='' />}
        </ImgWrapper>
      )}
    </StandardButton>
  );
};

export default BusyButton;
