import React, { FC } from 'react';
import { MainRouteTypes } from '../../../types';
import { GradientBackground } from '../common.style';
import { ButtonWrapper } from './LargeButton.styles';
import './LargeButton.css';

type myProps = {
  Caption1: string;
  Caption2: string;
  image?: any;
  onClick: (routeType: MainRouteTypes) => void;
  mainRoute: MainRouteTypes;
};
const LargeButton: FC<myProps> = ({
  Caption1,
  Caption2,
  image,
  onClick,
  mainRoute,
}) => {
  return (
    <ButtonWrapper onClick={() => onClick(mainRoute)}>
      <div className='Captions'>
        {Caption1}
        <div className='Caption2'>
          {Caption2}
          <img src={image} alt='' />
        </div>
      </div>
    </ButtonWrapper>
  );
};

export default LargeButton;
