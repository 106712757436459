import React from 'react';
import './PrivacyPolicy.css';
const PrivacyPolicy = () => {
  return (
    <div className='privacyPolicyWrapper'>
      <h1>Privacy Policy - DK Associates</h1>
      <p>
        This Privacy Policy governs the manner in which DK Associates (DK
        PRODUCT DESIGN SOLUTIONS LLC) of New Hampshire collects, uses, maintains
        and discloses information collected from users/Tenants (each, a “User”)
        of the https://dkassociates.us/ website (“Site”). This privacy policy
        applies to the Site and all products and services offered by
        DK-Associates.
      </p>

      <h2>Personal Identification Information</h2>
      <p>
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, fill out a form, and in connection with other activities,
        services, features or resources we make available on our Site. Users may
        be asked for, as appropriate, name, email address, phone number. Users
        may, however, visit our Site anonymously. We will collect personal
        identification information from Users only if they voluntarily submit
        such information to us. Users can always refuse to supply personal
        identification information, except that it may prevent them from
        engaging in certain Site related activities.
      </p>
      <h2>Web browser cookies</h2>
      <p>
        Our Site may use “cookies” to enhance User experience. User’s web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>
      <h2>How we protect your information</h2>
      <p>
        We adopt appropriate data collection, storage, and processing practices
        and security measures to protect against unauthorized access,
        alteration, disclosure or destruction of your personal information,
        username, password, transaction information and data stored on our Site.
        Sensitive and private data exchange between the Site and its Users
        happens over an SSL secured communication channel and is encrypted and
        protected with digital signatures.
      </p>
      <h2>Sharing your personal information</h2>
      <p>
        We do not sell, trade, or rent Users personal identification information
        to others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates, and advertisers
        for the purposes outlined above.
      </p>
      <h2>SMS Text Messaging Terms And Conditions</h2>
      <p>
        DK-Associates provides this policy to explain how we use SMS text
        messaging. Please read these SMS Text Messaging Terms & Conditions ("SMS
        Terms") carefully. By signing a rental lease agreement you expressly
        consent to receive non-marketing text messages from DK-Associates. These
        messages can be recurring or one-time. You may opt out of these
        communications at any time by follwing the procedure established by the
        text messages. For example, replaying "STOP" to any message you
        received. After this, you will no longer receive messages from that
        particular short code. If you want to join again, you can text "JOIN"
        and DK-Associates will start sending messages to you again.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
