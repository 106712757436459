import React, { FC } from 'react';
import _ from 'lodash';
import { AppProps } from '../../types';
import InputMask from 'react-input-mask';
import Address from '../common/Address';
import {
  AddressWrapper,
  Layout,
  SectionContainer,
} from '../common/common.style';
import SelectedInput from '../common/SelectedInput';
import Field from '../common/Field/Field';

const References: FC<AppProps> = ({ data, namespace, onChange }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{`${namespace} Reference`}</div>
      <div className='fieldsLayout'>
        <Field>
          <div>Name:</div>
          <input
            className='fieldInput'
            name={`${namespace}Name`}
            value={_.get(data, `${namespace}Name`, '')}
            onChange={onChange}
          />
        </Field>
        <Field>
          <div>Phone:</div>
          <InputMask
            className='fieldInput'
            mask='(999) 999 9999'
            name={`${namespace}Phone`}
            value={_.get(data, `${namespace}Phone`, '')}
            onChange={onChange}
          />
        </Field>
        <Field>
          <div>Length of relationship(years):</div>
          <SelectedInput
            className='fieldInput'
            name={`${namespace}LengthofRelationship`}
            value={_.get(data, `${namespace}LengthofRelationship`, 0)}
            onChange={onChange}
          />
          {/* <input
              name={`${namespace}LengthofRelationship`}
              value={_.get(data, `${namespace}LengthofRelationship`, 0)}
              onChange={onChange}
              onFocus={handleFocus}
            /> */}
        </Field>
        <AddressWrapper>
          <Address onChange={onChange} namespace={namespace!} data={data} />
        </AddressWrapper>
      </div>
    </SectionContainer>
  );
};

export default References;
