import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Field,
  FieldBorder,
  SmallSwitchLabel,
  StandardButton,
  SubmitButtonCover,
} from '../common/common.style';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useHistory } from 'react-router-dom';
import { AppProps } from '../../types';

const ConsentAndSubmit: FC<AppProps> = ({ submitApp }) => {
  const history = useHistory();
  const [approved, setApproved] = useState<boolean>(false);
  const scrollEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (approved) {
      scrollEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [approved]);
  const onClicked = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ): void => {
    const isApproved = approved;
    setApproved(!isApproved);
  };
  return (
    <div style={{ maxWidth: '405px', margin: 'auto' }}>
      <Field>
        <FieldBorder>
          <FormGroup>
            <SmallSwitchLabel>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={approved}
                    name='judgementPending'
                  />
                }
                label='I Authorize "DK-Associates" to pull my credit and conduct a background and past evictions check'
                onChange={onClicked}
              />
            </SmallSwitchLabel>
          </FormGroup>
        </FieldBorder>
      </Field>
      <div ref={scrollEndRef}>
        {approved && (
          <StandardButton
            onClick={() => {
              submitApp!(history);
            }}>
            Submit Application
          </StandardButton>
        )}
      </div>
    </div>
  );
};

export default ConsentAndSubmit;
