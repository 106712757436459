import React, { FC, useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  DATA_KEY,
  EMAIL_KEY,
  emptyApp,
  GET_APPLICATION,
  PAYMENTS_ROUTE,
  routes,
  SAVE_LIC,
  SUBMIT_APP,
} from '../../constants';
import { useForm } from '../../Hooks/useForm';
import { appData } from '../../types';
import { getApiUrl } from '../../utils/utils';
import Buttons from '../common/Buttons';
import { MainArea } from '../common/common.style';
import Routes from '../Routes/Routes';
import './App.css';

export const CREDITCARD = 'cc';
export const SCREENWIDTH = 'screenWidth';
const App: FC = () => {
  const [data, setData] = useState<appData>(emptyApp);
  const { onChange } = useForm(data, setData);
  const [hideButtons, setHideButtons] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const scrollEndRef = useRef<null | HTMLDivElement>(null);
  const viewRef = useRef<null | HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get(CREDITCARD);
  if(window.innerWidth > 450){
    localStorage.setItem(SCREENWIDTH, "desktop");
  } else {
    localStorage.setItem(SCREENWIDTH, "mobile");
  }
  if (code) {
    localStorage.setItem(CREDITCARD, code);
    setTimeout(() => {
      localStorage.removeItem(CREDITCARD);
    }, 60000);
  }
  console.log(code);

  useEffect(() => {
    const localEmail = localStorage.getItem(EMAIL_KEY);
    const localData = localStorage.getItem(DATA_KEY);
    if (localData) {
      const newData = JSON.parse(localData);
      setData({ ...newData });
    } else if (localEmail) {
      const screen = localStorage.getItem(SCREENWIDTH);
      fetch(getApiUrl(GET_APPLICATION, `Email=${localEmail}`))
        .then((response) => response.json())
        .then((j: appData) => {
          let dob: string = j.DateOfBirth ? '1970-01-01' : j.DateOfBirth;
          setData({ ...j,Screen:screen || '', Verified: j.Verified, DateOfBirth: dob });
        });
    }
  }, []);

  useEffect(() => {
    viewRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  }, [currentPage]);
  useEffect(() => {
    if (data.Occupants && data.Occupants.length > 0) {
      scrollEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data]);

  const submitApp = (history: any) => {
    const frontLic = data.LicFront;
    const backLic = data.LicBack;
    delete data.LicFront;
    delete data.LicBack;
    fetch(getApiUrl(SUBMIT_APP), {
      method: 'post',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data1) => {
        //clear local storage
        //reset data to empty object
        localStorage.removeItem(DATA_KEY);
        localStorage.removeItem(EMAIL_KEY);
        setData(emptyApp);
        if (history) {
          history.push(PAYMENTS_ROUTE);
          setHideButtons(true);
        }
      });
    const url2 = getApiUrl(SAVE_LIC);
    const json = { appId: data.AppId, front: frontLic, back: backLic };
    fetch(url2, {
      method: 'post',
      body: JSON.stringify(json),
    });
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <div className='MainPage'>
          <div className='dka-header'>
            <div>
              DK Associates
              {data.MainRoute && `- ${data.MainRoute}`}
            </div>
            <div className='dka-subheader'>Phone: 1-(603)-817-1397</div>
            <div className='dka-subheader'>
              P.O.Box 1630 Portsmouth, NH 03802
            </div>
          </div>

          <BrowserRouter>
            <MainArea ref={viewRef}>
              <Routes
                data={data}
                onChange={onChange}
                setData={setData}
                ref={scrollEndRef}
                submitApp={submitApp}
              />
            </MainArea>
            {data.Verified && !hideButtons && (
              <Buttons
                data={data}
                setData={setData}
                routes={routes}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </BrowserRouter>
        </div>
      </header>
    </div>
  );
};

export default App;
