import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { AppProps } from '../../types';
import './DrivingLic.css';

const DrivingLic: FC<AppProps> = ({ data, setData, onChange }) => {
  const height = 160;
  const width = 300;
  const [open, setOpen] = useState<boolean>(false);
  const videoConstraints = {
    width,
    height,
    facingMode: 'environment',
  };
  const webcamRef = React.useRef<any>(null);
  //   const imageRef = useRef<any>(null);
  const captureFront = () => {
    //@ts-ignore
    const _imageSrc = webcamRef.current.getScreenshot();
    const _data = { ...data, LicFront: _imageSrc };
    const e = {
      target: {
        name: 'LicFront',
        value: _imageSrc,
        dataset: {},
      },
    };
    onChange(e);
    //setData(_data);
  };

  const captureBack = () => {
    //@ts-ignore
    const _imageSrc = webcamRef.current.getScreenshot();
    const _data = { ...data, LicBack: _imageSrc };
    setData(_data);
  };

  return (
    <>
      <Webcam
        audio={false}
        height={height}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        width={width}
        videoConstraints={videoConstraints}
      />
      <div>
        <span>Scan/Pic Driving License</span>
        <br />
        <button className='btn' onClick={captureFront}>
          Front Side
        </button>
        <button className='btn' onClick={captureBack}>
          Back Side
        </button>
      </div>

      <div className='license'>
        <div className='licLabel'>
          Front
          <img className='licPic' src={data.LicFront} alt='' />
        </div>
        <div className='licLabel'>
          Back
          <img className='licPic' src={data.LicBack} alt='' />
        </div>
      </div>
    </>
  );
};

export default DrivingLic;
