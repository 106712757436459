import React from 'react';
import styled from 'styled-components';
import { applicationFee } from '../../constants';

const Section = styled.div`
  font-size: 15px;
  width: 675px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
    width: 340px;
  }
`;

const Declarations = () => {
  return (
    <div>
      <Section>
        I declare that the foregoing information is true and correct, I
        authorize its verification and the obtaining consumer credit report.
      </Section>
      <Section>
        {`I understand there is a non refundable fee of ${applicationFee} for the`}
        verification of this application and background check.
      </Section>
      <Section>
        I agree that Landlord may terminate any agreement entered into in
        reliance of any misstatement made above.
      </Section>
      <Section>
        Once Application has been approved and security deposit check has
        cleared the bank, should applicant decide they do not need the apartment
        the security deposit will be none refunable.
      </Section>
    </div>
  );
};

Declarations.propTypes = {};

export default Declarations;
