import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';

type MyProps = {
  open: boolean;
  setOpen: Function;
};
const MissingLicDialog: FC<MyProps> = ({ open, setOpen }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{'Scan / Pic License'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Before you continue please take a picture of you driving license or
          any Picture/ID. Both sides are needed in order to complete the App.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingLicDialog;
