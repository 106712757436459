import React, { FC } from 'react';
import _ from 'lodash';
import {
  AddressLayout,
  FieldLeft,
  StateField,
  WideField1,
  WideField2,
} from './common.style';
import States from './StatesDropDown';
import Field from './Field/Field';

type MyProps = {
  namespace: string;
  data: any;
  onChange: any;
};

const Address: FC<MyProps> = ({ namespace, data, onChange }) => {
  return (
    <div className='fieldsLayout addressLayout'>
      <Field>
        <div>Address1:</div>
        <input
          className='fieldInput'
          name={`${namespace}.Address.Address1`}
          value={_.get(data, `${namespace}.Address.Address1`)}
          onChange={onChange}
        />
      </Field>
      <Field>
        <div>Address2:</div>
        <input
          className='fieldInput'
          name={`${namespace}.Address.Address2`}
          value={_.get(data, `${namespace}.Address.Address2`)}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Field>
          <div>City:</div>
          <input
            className='fieldInput'
            name={`${namespace}.Address.City`}
            value={_.get(data, `${namespace}.Address.City`)}
            onChange={onChange}
          />
        </Field>
      </Field>
      <Field>
        <div>State:</div>
        <States
          name={`${namespace}.Address.State`}
          value={_.get(data, `${namespace}.Address.State`)}
          onChange={onChange}
          data={data}
        />
      </Field>
      <Field>
        <Field>
          <div>Zip code:</div>
          <input
            className='fieldInput'
            name={`${namespace}.Address.Zipcode`}
            value={_.get(data, `${namespace}.Address.Zipcode`)}
            onChange={onChange}
          />
        </Field>
      </Field>
    </div>
  );
};

export default Address;
