import styled from 'styled-components';
import { GradientBackground } from '../common.style';

export const ButtonWrapper = styled.a`
  width: 60%;
  padding: 10px;
  display: grid;
  align-items: center;
  height: 200px;
  border-radius: 30px;
  background-image: linear-gradient(to bottom right, rgb(0, 121, 142), yellow);
  color: white;
  border-top: 3px solid white;
  border-left: 3px solid white;
  border-bottom: 6px solid black;
  border-right: 6px solid black;
  :active {
    border-top: 6px solid black;
    border-left: 6px solid black;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
  }
  @media (max-width: 600px) {
    height: 120px;
  }
`;
