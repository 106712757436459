import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Field,
  FieldBorder,
  Layout,
  RequiredField,
  SectionContainer,
  SectionHeader,
} from '../common/common.style';
import { DATA_KEY } from '../../constants';
import { AppProps } from '../../types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  &::placeholder {
    font-size: 35px;
  }
`;

const GeneralQuestions: FC<AppProps> = ({
  data,
  setData,
  onChange,
  namespace = '',
}) => {
  const [commentsLength, setCommentsLength] = useState<number>(0);
  const [maxLen] = useState<number>(1000);
  useEffect(() => {
    if (!data.firearms && data.typeOfFirearm) {
      const newData = { ...data, typeOfFirearm: '' };
      setData(newData);
    }

    if (!data.anyPets && data.typeOfPet) {
      const newData = { ...data, typeOfPet: '' };
      setData(newData);
    }
  }, [data, setData, data.firearms]);
  useEffect(() => {
    const comments = data.comments || '';
    const len = comments.length;
    if (len > maxLen) {
      const _comments = data.comments.substring(0, maxLen);
      setData({ ...data, comments: _comments });
      setCommentsLength(len - 1);
    } else {
      setCommentsLength(len);
    }
  }, [data, setData, data.comments, maxLen]);
  const onClicked = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ): void => {
    const { name } = event.target as HTMLButtonElement;
    const e = {
      target: {
        ...event.target,
        checked: !checked,
        type: 'checkbox',
        name: name,
      },
    };
    onChange(e);
    localStorage.setItem(DATA_KEY, JSON.stringify(data));
  };
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Additional Questions</div>
      <Layout>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={data.filedPetitioinForBankruptcy}
                    name='filedPetitioinForBankruptcy'
                  />
                }
                label='Have you filed a petition for bankruptcy?'
                onChange={onClicked}
              />
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={data.haveBeenEvicted}
                    name='haveBeenEvicted'
                  />
                }
                label='Have you ever been evicted from any tenancy?'
                onChange={onClicked}
              />
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={data.refuseToPayRent}
                    name='refuseToPayRent'
                  />
                }
                label='Have you ever willfully and intentionally refused to pay any rent
              when due?'
                onChange={onClicked}
              />
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch size='small' checked={data.smoking} name='smoking' />
                }
                label='Are you smoking?(click if Yes)'
                onChange={onClicked}
              />
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={data.judgementPending}
                    name='judgementPending'
                  />
                }
                label='Do you have any legal judgement pending or against you?'
                onChange={onClicked}
              />
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={data.firearms}
                    name='firearms'
                  />
                }
                label='Do you posses any fire arms?'
                onChange={onClicked}
              />
              {data.firearms && (
                <Column>
                  <div>What type of firearm:</div>
                  <div>
                    <RequiredField required={data.typeOfFirearm === ''}>
                      <input
                        name='typeOfFirearm'
                        value={data.typeOfFirearm}
                        onChange={onChange}
                      />
                    </RequiredField>
                  </div>
                </Column>
              )}
            </FormGroup>
          </FieldBorder>
        </Field>

        <Field>
          <FieldBorder>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch size='small' checked={data.anyPets} name='anyPets' />
                }
                label='Do you have any pets?'
                onChange={onClicked}
              />
              {data.anyPets && (
                <Column>
                  <div>What type of pet:</div>
                  <div>
                    <RequiredField required={data.typeOfPet === ''}>
                      <input
                        name='typeOfPet'
                        value={data.typeOfPet}
                        onChange={onChange}
                      />
                    </RequiredField>
                  </div>
                </Column>
              )}
            </FormGroup>
          </FieldBorder>
        </Field>
        <Field>
          <FieldBorder>
            <Column>
              <TextareaAutosize
                className='fieldInput'
                aria-label='empty textarea'
                placeholder='Add more notes if needed'
                name='comments'
                onChange={onChange}
                value={data.comments}
                style={{ width: '95%' }}
              />
              <label>
                {commentsLength}/{maxLen}
              </label>
            </Column>
          </FieldBorder>
        </Field>
      </Layout>
    </SectionContainer>
  );
};

export default GeneralQuestions;
