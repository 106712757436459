import React, { FC, useCallback, useState } from 'react';
import BusyButton from '../common/BusyButton';
import {
  ContinueWrapper,
  ErrorMessage,
  StandardButton,
  SuccessMessage,
  VerifyAddressBlock,
} from '../common/common.style';
import { AppProps } from '../../types';
import { CONFIRM, RESEND_EMAIL, ROOT_URL, routes } from '../../constants';
import { useHistory } from 'react-router-dom';
import { getApiUrl } from '../../utils/utils';

const VerifyEmailAddress: FC<AppProps> = ({ data, setData }) => {
  const history = useHistory();
  const [token, setToken] = useState<string>('');
  const [prestine, setPrestine] = useState<boolean>(true);
  const [showTokenLoader, setShowTokenLoader] = useState<boolean>(false);
  const verifyToken = () => {
    setShowTokenLoader(true);
    //const url = `${ROOT_URL}confirm.php?cmd=${token}&cb=${new Date().getTime()}`;
    fetch(getApiUrl(CONFIRM, `cmd=${token}`))
      .then((response) => response.json())
      .then((data1) => {
        setShowTokenLoader(false);
        setPrestine(false);
        const isSuccess = data1.status === 'success';
        setData({ ...data, tokenSuccess: isSuccess });
      })
      .catch((error) => {
        setShowTokenLoader(false);
        console.log(error);
      });
  };

  const continueFlow = () => {
    const newData = {
      ...data,
      Verified: true,
      mainRoute: 'Rental Application',
    };
    setData(newData);
    history.push(routes[2]);
  };

  const resendEmail = useCallback(() => {
    fetch(getApiUrl(RESEND_EMAIL, `email=${data.Email}`))
      .then((response) => response.json())
      .then((data1) => {
        console.log(data.Email);
      });
  }, [data.Email]);
  return (
    <VerifyAddressBlock>
      Before you continue with the application, we need to verify your email
      address.
      <br />
      Please check your email for the Application-Token.
      <div className='token-buttons'>
        <div>
          <BusyButton
            OnClick={resendEmail}
            ShowLoader={false}
            UseLoader={true}
            Caption='Resend Email...'></BusyButton>
        </div>
        <div className='token-controls'>
          <input
            className='fieldInput'
            placeholder='Paste TOKEN here'
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <BusyButton
            OnClick={verifyToken}
            ShowLoader={showTokenLoader}
            UseLoader={true}
            Caption='Verify Token'></BusyButton>
        </div>

        <p>
          if you didn't receive an email, please make sure to check your SPAM
          folder and click the button above again.
        </p>
        {!prestine && (
          <div>
            {!data.tokenSuccess && (
              <ErrorMessage>
                The Token you applied did not work, please try again or request
                a new email.
              </ErrorMessage>
            )}
            {data.tokenSuccess && (
              <>
                <SuccessMessage>
                  Your email account is confirmed. You may continue.
                </SuccessMessage>
                <ContinueWrapper>
                  <StandardButton onClick={continueFlow}>
                    Click to continue...
                  </StandardButton>
                </ContinueWrapper>
              </>
            )}
          </div>
        )}
      </div>
    </VerifyAddressBlock>
  );
};

export default VerifyEmailAddress;
