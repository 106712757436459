import React from 'react';
import { Route } from 'react-router-dom';
import {
  PAYMENTSACH_ROUTE,
  PAYMENTS_ROUTE,
  routes,
  SUCCESS_ROUTE,
  PRIVACY_POLICY,
} from '../../constants';
import { AppProps } from '../../types';
import AddMoreIncomes from '../AddMoreIncomes/AddMoreIncomes';
import Declarations from '../Declarations/Declarations';
import DrivingLic from '../DrivingLic/DrivingLic';
import ConsentAndSubmit from '../GeneralQuestions/ConsentAndSubmit';
import GeneralQuestions from '../GeneralQuestions/GeneralQuestions';
import Landing from '../Landing/Landing';
import Occupants from '../Occupants/Occupants';
import Occupation from '../Occupation/Occupation';
import Payments from '../Payments/Payments';
import Success from '../Payments/Success';
import PaymentsAch from '../PaymentsAch/PaymentsAch';
import PersonalData from '../PersonalData/PersonalData';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import References from '../References/References';
import RentalData from '../RentalData/RentalData';
import ProtectedRoute from './ProtectedRoute';
//https://www.carlrippon.com/react-forwardref-typescript/
const Routes = React.forwardRef<HTMLDivElement, AppProps>(
  ({ data, onChange, setData, submitApp }, scrollRef) => {
    return (
      <>
        <Route path={PAYMENTS_ROUTE} render={() => <Payments />} />
        <Route path={PAYMENTSACH_ROUTE} render={() => <PaymentsAch />} />
        <Route path={SUCCESS_ROUTE} render={() => <Success />} />
        <Route path={PRIVACY_POLICY} render={() => <PrivacyPolicy />} />
        <Route
          path={routes[0]}
          render={() => (
            <Landing data={data} setData={setData} onChange={onChange} />
          )}
        />
        <Route
          path={routes[1]}
          render={() => (
            <PersonalData data={data} onChange={onChange} setData={setData} />
          )}
        />
        <ProtectedRoute
          IsVerified={data.Verified}
          routes={routes}
          path={routes[2]}
          render={() => (
            <>
              <RentalData
                setData={setData}
                data={data}
                onChange={onChange}
                namespace='Current'
              />
              <RentalData
                setData={setData}
                data={data}
                onChange={onChange}
                namespace='Previous'
              />
              <Occupants data={data} onChange={onChange} setData={setData} />
              <AddMoreIncomes
                data={data}
                onChange={onChange}
                setData={setData}
              />
              <div ref={scrollRef} />
            </>
          )}
        />
        <ProtectedRoute
          IsVerified={data.Verified}
          routes={routes}
          path={routes[3]}
          render={() => (
            <>
              <Occupation data={data} onChange={onChange} namespace='Current' />
              <Occupation
                data={data}
                onChange={onChange}
                namespace='Previous'
              />
            </>
          )}
        />
        <ProtectedRoute
          IsVerified={data.Verified}
          routes={routes}
          path={routes[4]}
          render={() => (
            <>
              <References data={data} onChange={onChange} namespace='Bank' />
              <References
                data={data}
                onChange={onChange}
                namespace='Personal'
              />
              <References data={data} onChange={onChange} namespace='Nearest' />
            </>
          )}
        />
        <ProtectedRoute
          IsVerified={data.Verified}
          routes={routes}
          path={routes[5]}
          render={() => (
            <>
              <DrivingLic data={data} onChange={onChange} setData={setData} />
            </>
          )}
        />

        <ProtectedRoute
          IsVerified={data.Verified}
          routes={routes}
          path={routes[6]}
          render={() => (
            <>
              <GeneralQuestions
                data={data}
                onChange={onChange}
                setData={setData}
              />
              <Declarations />
              <ConsentAndSubmit
                data={data}
                onChange={onChange}
                setData={setData}
                submitApp={submitApp}
              />
            </>
          )}
        />
      </>
    );
  },
);

export default Routes;
