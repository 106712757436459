import React from 'react';

const PaymentsAch = () => {
  return (
    <div>
      <iframe
        src='https://smartpay.profitstars.com/express/DK%20PRODUCT%20DESIGN'
        width='100%'
        title='ach'></iframe>
    </div>
  );
};

export default PaymentsAch;
