import React, { FC } from 'react';
import { RouteProps } from '../../types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute: FC<RouteProps> = ({
  IsVerified,
  path,
  render,
  routes,
}) => {
  if (IsVerified) {
    return <Route path={path} render={render} />;
  }

  return <Redirect to={routes[0]} />;
};

ProtectedRoute.propTypes = {};

export default ProtectedRoute;
