import React, { FC, useState } from 'react';
import './PersonalData.css';
import { AppProps } from '../../types';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ContinueWrapper,
  EmailBlocker,
  EmailWrapper,
  ErrorMessage,
  Layout,
  RequiredField,
  SectionContainer,
  StandardButton,
} from '../common/common.style';
import {
  calcAgeYears,
  getDate,
  onDateChanged,
  validateEmail,
} from '../../utils/utils';
import { CREATE_NEW_APP, EMAIL_KEY, MIN_AGE, RESET } from '../../constants';
import { getApiUrl } from '../../utils/utils';
import States from '../common/StatesDropDown';
import VerifyEmailAddress from './VerifyEmailAddress';
import BusyButton from '../common/BusyButton';
import Field from '../common/Field/Field';

const PersonalData: FC<AppProps> = ({ data, onChange, setData }) => {
  const [continueClicked, setContinueClicked] = useState<boolean>(false);
  const [showEmailExists, setShowEmailExists] = useState<boolean>(false);
  const verifyEmail = () => {
    setContinueClicked(true);
    setShowEmailExists(false);
    fetch(getApiUrl(CREATE_NEW_APP), {
      method: 'post',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data1) => {
        if (data1.status === 'error') {
          setShowEmailExists(true);
        } else {
          const newData = {
            ...data,
            isEmailVerified: false,
            AppId: data1.appId,
            PersonalDataId: data1.personalDataId,
          };
          setData(newData);
          localStorage.setItem(EMAIL_KEY, newData.Email);
        }
      });
  };

  const Reset = () => {
    setContinueClicked(false);
    setShowEmailExists(false);
    fetch(getApiUrl(RESET, `AppId=${data.AppId}`))
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'removed') {
          setData({ ...data, AppId: null, PersonalDataId: null });
          localStorage.removeItem(EMAIL_KEY);
        }
      });
  };
  console.log(data);
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Personal Data</div>
      <div className='fieldsLayout'>
        <Field>
          <div>Full Name:</div>
          <div>
            <RequiredField required={data.FullName === ''}>
              <input
                className='fieldInput'
                name='FullName'
                value={data.FullName}
                onChange={onChange}
              />
            </RequiredField>
          </div>
        </Field>
        <Field>
          <div>Apartment address:</div>
          <div>
            <RequiredField required={data.ApartmentAddress === ''}>
              <input
                className='fieldInput'
                name='ApartmentAddress'
                value={data.ApartmentAddress}
                onChange={onChange}
                placeholder='The one you apply for'
              />
            </RequiredField>
          </div>
        </Field>
        <Field>
          <div>Phone number:</div>
          <RequiredField required={data.PhoneNumber === ''}>
            <div>
              <InputMask
                className='fieldInput'
                mask='(999) 999 9999'
                name='PhoneNumber'
                value={data.PhoneNumber}
                onChange={onChange}
              />
            </div>
          </RequiredField>
        </Field>
        <Field>
          <div>Emergency Phone number:</div>
          <div>
            <InputMask
              className='fieldInput'
              mask='(999) 999 9999'
              name='EmergencyPhone'
              value={data.EmergencyPhone}
              onChange={onChange}
            />
          </div>
        </Field>
        <Field>
          <div>Social Security Number:</div>
          <RequiredField required={data.SSN === ''}>
            <div>
              <InputMask
                className='fieldInput'
                mask='999-99-9999'
                name='SSN'
                value={data.SSN}
                onChange={onChange}
              />
            </div>
          </RequiredField>
        </Field>

        <Field>
          <div>Date of birth:</div>
          <RequiredField required={calcAgeYears(data.DateOfBirth) < MIN_AGE}>
            <div style={{ display: 'grid' }}>
              <DatePicker
                className='fieldInput'
                selected={getDate(data.DateOfBirth)}
                name='DateOfBirth'
                id='DateOfBirth'
                onChange={(e) => {
                  onDateChanged(e, 'DateOfBirth', onChange);
                }}
              />
              {calcAgeYears(data.DateOfBirth) < MIN_AGE && (
                <span style={{ color: 'red' }}>
                  Minimum age to apply:{MIN_AGE}
                </span>
              )}
            </div>
          </RequiredField>
        </Field>

        <Field>
          <div>Driving License Number:</div>
          <RequiredField required={data.DrivingLicenceNumber === ''}>
            <div>
              <input
                className='fieldInput'
                name='DrivingLicenceNumber'
                value={data.DrivingLicenceNumber}
                onChange={onChange}
              />
            </div>
          </RequiredField>
        </Field>
        <Field>
          <div>Car make and model:</div>
          <RequiredField required={data.CarMakeModel === ''}>
            <div>
              <input
                className='fieldInput'
                name='CarMakeModel'
                value={data.CarMakeModel}
                onChange={onChange}
              />
            </div>
          </RequiredField>
        </Field>
        <Field>
          <div>Car Plate Number:</div>
          <RequiredField required={data.CarPlateNumber === ''}>
            <div>
              <input
                className='fieldInput'
                name='CarPlateNumber'
                value={data.CarPlateNumber}
                onChange={onChange}
              />
            </div>
          </RequiredField>
        </Field>
        <Field>
          <div>Email:</div>
          <RequiredField required={!validateEmail(data.Email)}>
            <EmailWrapper>
              <input
                className='fieldInput'
                name='Email'
                autoComplete='off'
                value={data.Email}
                onChange={onChange}
              />
              {((!showEmailExists && data.AppId) || data.Verified) && (
                <EmailBlocker></EmailBlocker>
              )}
            </EmailWrapper>
          </RequiredField>
        </Field>
        <Field>
          <div>Driving License State:</div>
          <RequiredField required={data.LicenseState === ''}>
            <div>
              <States
                name='LicenseState'
                value={data.LicenseState}
                onChange={onChange}
                data={data}
              />
            </div>
          </RequiredField>
        </Field>
      </div>
      {validateEmail(data.Email) && !data.Verified && (
        <div>
          <ContinueWrapper>
            <StandardButton onClick={verifyEmail}>
              {continueClicked
                ? 'Verify your email...'
                : 'Click to continue...'}
            </StandardButton>
            {!data.tokenSuccess && continueClicked && (
              <BusyButton
                OnClick={Reset}
                ShowLoader={false}
                UseLoader={true}
                Caption='Reset'></BusyButton>
            )}
          </ContinueWrapper>
          {showEmailExists && (
            <ErrorMessage>
              This Email is not available, please choose a different email.
            </ErrorMessage>
          )}
          <VerifyEmailAddress
            data={data}
            onChange={onChange}
            setData={setData}></VerifyEmailAddress>
        </div>
      )}
    </SectionContainer>
  );
};

export default PersonalData;
