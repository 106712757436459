import { appData } from './types';

export const ROOT_URL = 'https://dkassociates.us/api/';
export const DATA_KEY = 'data';
export const EMAIL_KEY = 'email';

export const applicationFee = '$45.00';
export const routes = [
  '/Landing',
  '/PersonalData',
  '/RentalData',
  '/Occupation',
  '/References',
  '/License',
  '/GeneralQuestions',
];

export const PAYMENTS_ROUTE = '/Payments';
export const PAYMENTSACH_ROUTE = '/PaymentsAch';
export const SUCCESS_ROUTE = '/Success';
export const PRIVACY_POLICY = '/privacy-policy';
export const MIN_AGE = 18;

//PHP modules
export const GET_APPLICATION = 'getApplication';
export const SUBMIT_APP = 'submitApp';
export const SAVE_LIC = 'saveLic';
export const CREATE_NEW_APP = 'createNewApplication';
export const RESET = 'reset';
export const CONFIRM = 'confirm';
export const RESEND_EMAIL = 'resendEmail';

export const emptyApp: appData = {
  MainRoute: '',
  Verified: false,
  FullName: '',
  PhoneNumber: '',
  EmergencyPhone: '',
  SSN: '',
  DateOfBirth: '01/01/2000',
  ApartmentAddress: '',
  Email: '',
  DrivingLicenceNumber: '',
  LicFront: '',
  LicBack: '',
  CarMakeModel: '',
  CarPlateNumber: '',
  LicenseState: '',
  filedPetitioinForBankruptcy: false,
  haveBeenEvicted: false,
  refuseToPayRent: false,
  smoking: false,
  judgementPending: false,
  firearms: false,
  typeOfFirearm: '',
  anyPets: false,
  typeOfPet: '',
  comments: '',
  tokenSuccess: false,
};
