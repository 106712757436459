import styled from 'styled-components';

export const LandingWrapper = styled.div`
  padding-top: 30px;
  width: 100%;
  display: grid;
  //grid-template-columns: 1fr 1fr;
  //grid-template-rows: 1fr;
  justify-items: center;
  //grid-gap: 1px 9px;
  //@media (max-width: 600px) {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  //}
`;

export const TermsConditions = styled.div`
  /* position: absolute;
  right: 334px;
  top: 11px;
  font-size: 14px; */
  text-decoration: underline;
  cursor: pointer;
`;
