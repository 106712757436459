import React, { FC, useEffect, useState } from 'react';
import { appData, AppProps, IOccupant } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {
  CloseIcon,
  StandardButton,
  OccupantRecord,
  SectionContainer,
} from '../common/common.style';
import Field from '../common/Field/Field';

const Occupants: FC<AppProps> = ({ data, onChange, setData }) => {
  useEffect(() => {
    if (!data.Occupants) {
      const newData: appData = { ...data, Occupants: [] };
      setData(newData);
    }
  }, []);
  const addOccupant = () => {
    const newData = { ...data };
    if (!newData.Occupants) {
      newData.Occupants = [];
    }
    newData.Occupants.push({});
    setData(newData);
  };
  const removeRecord = () => {
    const newData = { ...data };
    newData.Occupants!.pop();
    setData(newData);
  };
  // useEffect(() => {
  //     if (data.occupantNumber !== occupantNumber) {
  //         const event = {
  //             //newData[e.target.name] = e.target.value;
  //             target: {
  //                 name: 'occupantNumber',
  //                 value: occupantNumber,
  //                 dataset: {}
  //             }
  //         }
  //         onChange(event)
  //     }

  // }, [occupantNumber, onChange, data.occupantNumber])
  // const arr = []
  // for (let i = 0; i < data.occupantNumber; i++) {
  //     arr.push(i);
  // }
  //https://github.com/FortAwesome/Font-Awesome/tree/master/js-packages/%40fortawesome/free-regular-svg-icons
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Occupants</div>
      <StandardButton onClick={addOccupant}>
        {' '}
        click to add Occupant{' '}
      </StandardButton>
      <div className='fieldsLayout'>
        {(data.Occupants || []).map((occupant: IOccupant, index: number) => (
          <OccupantRecord>
            <CloseIcon onClick={() => removeRecord()}>
              <FontAwesomeIcon icon={faWindowClose} />
            </CloseIcon>
            <Field>
              <div>Name:</div>
              <input
                className='fieldInput'
                name={`Arr-Occupants-Name-${index}`}
                value={data.Occupants ? data.Occupants[index].Name : ''}
                onChange={onChange}
              />
            </Field>
            <Field>
              <div>Relationship:</div>
              <div>
                <input
                  className='fieldInput'
                  name={`Arr-Occupants-Relationship-${index}`}
                  value={
                    data.Occupants ? data.Occupants[index].Relationship : ''
                  }
                  onChange={onChange}
                />
              </div>
            </Field>
            <Field>
              <div>Age:</div>
              <div>
                <input
                  className='fieldInput'
                  name={`Arr-Occupants-Age-${index}`}
                  value={data.Occupants ? data.Occupants[index].Age : ''}
                  onChange={onChange}
                />
              </div>
            </Field>
          </OccupantRecord>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Occupants;
