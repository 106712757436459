import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PAYMENTSACH_ROUTE,
  PAYMENTS_ROUTE,
  PRIVACY_POLICY,
  routes,
} from '../../constants';
import { appData, AppProps, MainRouteTypes } from '../../types';
import LargeButton from '../common/LargeButton/LargeButton';
import { LandingWrapper, TermsConditions } from './Landing.styles';
import visa from '../../images/visa.png';
import ach from '../../images/ach_t.png';
import { CREDITCARD } from '../App/App';

const Landing: FC<AppProps> = ({ data, setData }) => {
  const history = useHistory();
  useEffect(() => {
    if (data.MainRoute !== '') {
      const newData: appData = { ...data, MainRoute: '' };
      setData(newData);
    }
  }, [data.MainRoute]);
  const onClick = (mainRoute: MainRouteTypes) => {
    const newData: appData = { ...data, MainRoute: mainRoute };
    setData(newData);
    if (mainRoute === 'Rental Application') {
      if (history) {
        history.push(routes[1]);
      }
    }

    if (mainRoute === 'Payments') {
      if (history) {
        history.push(PAYMENTS_ROUTE);
      }
    }

    if (mainRoute === 'PaymentsAch') {
      if (history) {
        //history.push(PAYMENTSACH_ROUTE);
        // @ts-ignore
        window.location =
          'https://smartpay.profitstars.com/express/DK%20PRODUCT%20DESIGN';
      }
    }
  };
  const onTermsClick = () => {
    history.push(PRIVACY_POLICY);
  };
  return (
    <LandingWrapper>
      <LargeButton
        Caption1='Click here to start a rental application!'
        Caption2=''
        onClick={onClick}
        mainRoute='Rental Application'
      />
      <LargeButton
        Caption1='Click here to make a payment!'
        Caption2='Pay (secured) with a check/bank (ACH)'
        image={ach}
        onClick={onClick}
        mainRoute='PaymentsAch'
      />
      {localStorage.getItem(CREDITCARD) && (
        <LargeButton
          // Caption1='Click here to make a payment!'
          Caption1=''
          // Caption2='Pay with a Creditcard'
          Caption2=''
          image={visa}
          onClick={onClick}
          mainRoute='Payments'
        />
      )}
      {/* <TermsConditions onClick={onTermsClick}>
        Terms and Conditions / SMS Policy
      </TermsConditions> */}
    </LandingWrapper>
  );
};

export default Landing;
