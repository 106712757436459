import React, { FC } from 'react';
import PropTypes from 'prop-types';

const SelectedInput: FC<any> = (props) => {
  const handleFocus = (e: any) => {
    e.target.select();
  };
  return <input {...props} onFocus={handleFocus} />;
};

export default SelectedInput;
